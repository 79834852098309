.upload-area {
  border: 2px dashed #cbd5e0; /* Light gray border */
  background-color: #f9fafb; /* Default light background */
  border-radius: 12px;
  padding: 24px;
  transition: border-color 0.3s ease;
}

.upload-area.active {
  border-color: #805ad5; /* Purple for active */
  background-color: #faf5ff;
}

.upload-area.error {
  border-color: #f56565; /* Red for error */
  background-color: #fff5f5;
}

.upload-label {
  font-size: 1rem;
  font-weight: 600;
  color: #4a4a4a;
  margin-bottom: 8px;
}

.upload-preview-container-single{
  display: flex;
}


.upload-label-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  cursor: pointer;
}

.upload-add-more {
  display: block;
  text-align: center;
  font-size: 1rem;
  color: #6b46c1;
  cursor: pointer;
  margin-top: 10px;
}


.upload-add-more {
  text-align: center;
  color: #6b46c1;
  font-weight: 500;
  margin-top: 16px;
  cursor: pointer;
}

.upload-add-more:hover {
  color: #5a3ea1;
}


.upload-container {
  width: 100%;
}

.upload-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.upload-area {
  padding: 1rem;
  border: 2px dashed #e5e7eb;
  border-radius: 0.5rem;
  transition: all 0.2s;
}

.upload-active {
  border-color: #3b82f6;
  background-color: #eff6ff;
}

.upload-error {
  border-color: #ef4444;
  background-color: #fef2f2;
}



.upload-label-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  cursor: pointer;
  color: #6b7280;
}

.upload-icon {
  margin-bottom: 1rem;
}

.upload-text {
  font-size: 0.875rem;
  font-weight: 500;
}

.upload-subtext {
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

.upload-add-more {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #3b82f6;
  color: white;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.upload-add-more:hover {
  background-color: #2563eb;
}

.upload-error-text {
  color: #ef4444;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}


.upload-preview {
  position: relative;
  cursor: move;
  touch-action: none;
  user-select: none;
  width: 100%;
  padding-top: 100%; /* This maintains a square aspect ratio */
  background-color: #f3f4f6;
  border-radius: 0.5rem;
  overflow: hidden;
}

.upload-preview:hover {
  transform: scale(1.02);
  transition: transform 0.2s;
}

.upload-preview img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures images fill the container while keeping the aspect ratio */
  border-radius: 0.5rem;
  will-change: transform;
}

.upload-preview-wrapper {
  width: 100%;
  position: relative;
}

.upload-preview {
  position: relative;
  width: 100%;
  background-color: #f3f4f6;
  border-radius: 0.5rem;
  overflow: hidden;
}

.upload-preview-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.upload-preview-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
  will-change: transform;
}

.upload-preview-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  width: 100%;
  padding: 16px;
}

.upload-remove-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgb(239 68 68);
  color: white;
  border: none;
  border-radius: 9999px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 100000;
  pointer-events: auto;
}

.upload-preview-wrapper:hover .upload-remove-button {
  opacity: 1;
  z-index: 100000;
}

.upload-remove-button:hover {
  background-color: rgb(220 38 38);
}


